<template>
  <b-card no-body>
    <b-card-body>
      <b-row class="mb-1">
        <b-col>
          <search :search-action="searchData" />
        </b-col>
        <b-col
          v-if="detailSearch"
          cols="12"
        >
          <filter-data :filter-action="getFilteredData" />
        </b-col>
      </b-row>
    </b-card-body>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
    >
      <template #cell(company)="data">
        <b-link
          class="text-body"
          :to="$route.path + '/detail/' + data.item.id"
        >
          <div>{{ data.item.company }}</div>
          <div class="font-small-3 text-primary">
            {{ data.item.name }}
          </div>
        </b-link>
      </template>
      <template #cell(country)="data">
        <div>{{ data.item.country }}</div>
        <div class="font-small-3 text-info">
          {{ data.item.city }}
        </div>
      </template>
      <template #cell(control)="data">
        <div class="text-right">
          <b-button
            variant="primary"
            :to="$route.path + '/detail/' + data.item.id"
            size="sm"
          >
            <FeatherIcon icon="EyeIcon" />
            Görüntüle
          </b-button>
        </div>
      </template>
    </b-table>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        :total-rows="dataCounts"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-card-footer>
  </b-card>
</template>
<script>
import {
  BCard, BCardBody, BRow, BCol, BTable, BPagination, BButton, BCardFooter, BLink,
} from 'bootstrap-vue'
import Search from '@/views/Admin/Customers/FilterData/Search.vue'
import FilterData from '@/views/Admin/Customers/FilterData/FilterData.vue'

const tableName = 'customers'
export default {
  name: 'CustomersIndex',
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BTable,
    BPagination,
    BButton,
    BCardFooter,
    BLink,
    Search,
    FilterData,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      fields: [
        {
          key: 'company',
          label: 'FİRMA ADI',
        },
        {
          key: 'phone',
          label: 'TELEFON',
          thStyle: { width: '200px' },
        },
        {
          key: 'sector',
          label: 'SEKTÖR',
          thStyle: { width: '200px' },
        },
        {
          key: 'country',
          label: 'BÖLGE',
          thStyle: { width: '160px' },
        },
        {
          key: 'control',
          label: '',
          thStyle: { width: '180px' },
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.company AS company`,
          `${tableName}.phone AS phone`,
          'sectors.title AS sector',
          'countries.title AS country',
          'cities.title AS city',
        ],
        limit: 20,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['customers/dataList']
    },
    dataCounts() {
      return this.$store.getters['customers/dataCounts']
    },
    detailSearch() {
      return this.$store.getters['customers/detailSearch']
    },
    filterData() {
      return this.$store.getters['customers/filterData']
    },
    searchKeyword() {
      return this.$store.getters['customers/search']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 20
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    searchData() {
      if (this.searchKeyword.keyword) {
        this.dataQuery.or_like = {
          'customers.company': this.searchKeyword.keyword,
          'customers.phone': this.searchKeyword.keyword,
          'customers.tax_number': this.searchKeyword.keyword,
        }
        this.getDataList()
      } else {
        this.dataQuery.or_like = {}
        this.getDataList()
      }
    },
    getFilteredData() {
      const where = {}
      const filterData = {
        id_sectors: [],
        id_activities: [],
        id_parkour_types: [],
      }
      if (this.filterData.id_meets) {
        where['customers.id_meets'] = this.filterData.id_meets
      }
      if (this.filterData.id_countries) {
        where['customers.id_countries'] = this.filterData.id_countries
      }
      if (this.filterData.id_cities) {
        where['customers.id_cities'] = this.filterData.id_cities
      }
      if (this.filterData.id_sectors) {
        filterData.id_sectors = this.filterData.id_sectors
      }
      if (this.filterData.id_activities) {
        filterData.id_activities = this.filterData.id_activities
      }
      if (this.filterData.id_parkour_types) {
        filterData.id_parkour_types = this.filterData.id_parkour_types
      }
      this.dataQuery.where = where
      this.dataQuery.filterData = filterData
      this.getDataList()
    },
    getDataList() {
      this.$store.dispatch('customers/getDataList', this.dataQuery)
    },
  },
}
</script>
